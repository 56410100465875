<template>
  <div class="container kuan">
    <div class="main">
      <topTimer />
      <div class="kd_flexcontainer">
        <!-- 下注框 -->
        <div class="order_wrap">
          <div class="player listmain">
            <div class="data">
              <h3>下注框<button class="tuima_btn" @click="tuimaon">退码</button></h3>
              <div class="inside-os">
                <div class="report">
                  <table class="com-table">
                    <thead>
                      <tr>
                        <th width="150px">注单编号</th>
                        <th>号码</th>
                        <th>赔率</th>
                        <th>金额</th>
                        <!-- <th>状态</th> -->
                        <th class="norightbor">
                          <span>全选</span>
                          <input
                            type="checkbox"
                            style="position: relative; top: 2px"
                          />
                        </th>
                        <th v-if="wjlist.length>12" style="width: 17px"></th>
                      </tr>
                    </thead>

                    <tbody id="tbody">
                      <tr v-for="item in wjlist" :key="item.orderid">
                        <td width="150px">{{item.touzhufangshi}}{{ item.orderid }}</td>
                        <td>{{ item.iteminfo }}</td>
                        <td><span class="red">{{ item.winodds }}</span></td>
                        <td>{{ item.amount }}</td>
                        <!-- <td>好</td> -->
                        <td>
                          <input type="checkbox" :value="item.orderid" v-model="item.selected" @click="seltuima($event,item)" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 停押号码 -->
        <div class="ty_wrap">
          <div class="player listmain">
            <div class="data">
              <h3>
                目前停押号码
                <select name="" id="">
                  <option value="222222">22222</option>
                  <option value="11111">11111</option>
                </select>
              </h3>
              <div class="inside-os">
                <div class="report">
                  <table class="com-table">
                    <thead>
                      <tr>
                        <th>号码</th>
                        <th>金额</th>
                        <th class="norightbor">全选</th>
                        <th width="17px"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <tr v-for="i in 200">
                        <td>XXX12</td>
                        <td>1234567</td>
                        <td>
                          <input type="checkbox" />
                        </td>
                      </tr> -->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 删出停押号码保留区 -->
        <div class="bl_wrap">
          <div class="player listmain">
            <div class="data">
              <h3>删除停押号码保留区</h3>
              <div class="inside-os">
                <div class="report">
                  <table class="com-table">
                    <thead>
                      <tr>
                        <th>号码</th>
                        <th class="norightbor">金额</th>
                        <th width="17"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <tr v-for="i in 300">
                        <td>XX12345</td>
                        <td>123445</td>
                      </tr> -->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ------------ -->
      </div>
      <!-- ---------------------- -->
      <div class="player listmain">
        <div class="data">
          <h3>
            <label :for="item.id" v-for="item in ordertypeArr"
            style="margin-right: 5px;"
            :key="item.id">
              {{item.name}}
              <input
              type="radio"
              :id="item.id"
              v-model="ordertype"
              :value="item.value"
              
            />
            </label>
             
            
            
          </h3>
          <section class="xiazhukuang">
            <div style="width:63px" v-if="!quanbao_bool"></div>
            <h1 v-if="quanbao_bool">全包</h1>
            <input type="checkbox" v-if="quanbao_bool" class="quanbaoinput" v-model="quanbao">
            <h1  v-if="haoma_bool" style="margin-left: 10px">号码</h1>
            <input
              class="xiazhulabel"
              ref="haoma"
              id="haoma"
              type="text"
              :disabled="haoma_disabled"
              v-if="haoma_bool"
              :maxlength="haomaleng"
              @change="haomachange1"
              @input="haomachange"
              v-model="haoma"
            />
            <!-- {{haomas}} -->
            <h1 style="margin-left: 5px">金额</h1>
            <input
              class="xiazhulabel"
              ref="amount"
              v-model="amount"
              type="number"
            />
            <button class="xzbtn" @click="xiazhuon">确定下注</button>
            <!-- <h1>角票</h1>
        <h1 class="green">123</h1> -->
            <h1 style="margin-left: 5px">赔率:</h1>
            <h1 class="red">{{ Odds }}</h1>
            <h1 style="margin-left: 5px">可下:</h1>
            <h1 class="red">{{ currentQianbao.amount }}</h1>
          </section>
        </div>

       <p v-if="setorderArr[0]"> {{orderName}}{{setorderArr[0]?setorderArr[0].label:''}}</p>
      </div>

      <!-- ---------------------------- -->
    </div>
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import mixins from "../mixins/mainMixins";
import { zhixuan,kua,hezhi,yiziding,erziding,yizixian,zuxuan3,zuxuan6,fushi,duizi } from "./pl3callData.js";
import {md55} from '../mixins/md5.js'

export default {
  name: "kuaida",
  props: [""],
  mixins: [mixins],
  components: {
    yushe,
    topTimer,
  },
  data() {
    return {
      haoma_disabled:false,
      quanbao:false,
      quanbao_bool:false,
      tuimaArr:[],
      haoma_bool:true,
      haomaleng:3,
      // zhixuan:直选   zusan:'组三' zuliu:组六  kua:跨
      // dan:单  shuang:双 da:大  xiao:小  fushi:复试
      // duizi:对子  hezhi:和值  dudan:独胆 yiding:一字定 erding:二字定
      ordertype:'zhixuan',
      orderName:'直选',
      ordertypeArr:[{id:'t1',value:'zhixuan',name:'直选'},{id:'t2',value:'zusan',name:'组三'}
    ,{id:'t3',value:'zuliu',name:'组六'},{id:'t4',value:'kua',name:'跨'},{id:'t5',value:'dan',name:'单'}
  ,{id:'t6',value:'shuang',name:'双'},{id:'t7',value:'da',name:'大'},{id:'t8',value:'xiao',name:'小'}
,{id:'t9',value:'fushi',name:'复式'},{id:'t10',value:'duizi',name:'对子'},{id:'t11',value:'hezhi',name:'和值'}
,{id:'t12',value:'dudan',name:'独胆'},{id:'t13',value:'yiding',name:'一定'},{id:'t14',value:'erding',name:'二定'}],
      // -------
      amount: "",
      Odds: "",
      setorderArr: [],
      isxian: false,
      quanzhuan: false,
      haoma: "",
      isDui: false,
      state: 0,
      wjlist:[]
    };
  },
  computed: {
    ...mapState(["userInfo", "currentGame",'currentQianbao','qwer']),
    xcount() {
      let count = 0;
      if (this.haoma.includes("X")) {
        var regex = new RegExp("X", "g");
        count = this.haoma.match(regex).length;
      }

      return count;
    },
  },
  watch: {
    quanbao(val){
      if(val){
        if(this.ordertype=='zuliu'){
          this.haoma_disabled=true;
          this.haoma="0123456789"
          this.getZuLiuData()
        }
        if(this.ordertype=='zusan'){
          this.haoma_disabled=true;
          this.haoma="0123456789"
          this.getZusanData()
        }
        if(this.ordertype=='duizi'){
          this.haoma_disabled=true;
          this.haoma="0123456789"
          this.getDuiziData()
        }
      }else{
        this.haoma=''
        this.haoma_disabled=false
      }
     
    },
    ordertype(val){
      this.setorderArr=[]
      this.haoma_disabled=false
      this.quanbao_bool=false
      this.quanbao=false;
      this.haoma_bool=true
      this.haoma=''
      this.Odds=''
      if(val=='fushi'){
        this.haomaleng=9
        this.orderName='复式'
      }
      if(val=='duizi'){
        this.haomaleng=1
        this.quanbao_bool=true
        this.orderName='对子'

      }
      if(val=='zusan'||val=='zuliu'){
        this.haomaleng=9
        this.quanbao_bool=true
        this.orderName=val=='zusan'?'组三':'组六'

      }
      if(val=='zhixuan'||val=='yiding'||val=='erding'){
        this.haomaleng=3
        if(val=='yiding'){
          this.orderName='一字定'
        }
        if(val=='erding'){
          this.orderName='二字定'
        }
        if(val=='zhixuan'){
          this.orderName='直选'
        }
      }
      if(val=='kua'||val=='dudan'){
        this.haomaleng=1
        this.orderName=val=='kua'?'':'独胆'
      }
      if(val=='fushi'){
        this.haomaleng=9
      }
      if(val=='hezhi'){
        this.haomaleng=2
        this.orderName=''
      }
      if(val=='dan'){
        this.haoma_bool=false
        this.setDanXiang({ResultID:3692,label:'单'})
        this.orderName=''
      }
      if(val=='shuang'){
        this.haoma_bool=false
        this.setDanXiang({ResultID:3693,label:'双'})
        this.orderName=''
      }
      if(val=='da'){
        this.haoma_bool=false
        this.setDanXiang({ResultID:3690,label:'大'})
        this.orderName=''
      }
      if(val=='xiao'){
        this.haoma_bool=false
        this.setDanXiang({ResultID:3691,label:'小'})
        this.orderName=''
      }
    },
    haoma(val) {
      if (val.length == 4) {
        this.shaixuanhaoma();
      }
    },
    quanzhuan() {
      if (this.quanzhuan) {
        this.isxian = false;
      }
    },
    isxian() {
      if (this.isxian) {
        this.quanzhuan = false;
      }
    },
  },

  created() {
    this.getweijieList()
    // this.getGroupNameOddsCS()
  },

  methods: {
    getGroupNameOddsCS(){
     
      
      let obj={
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        groupnames:'fs',
        pan:'A',
        roomeng:this.currentGame.roomeng
      }
      this.$http.post('getcommongroupodds',obj).then((res)=>{
        if(res.Status){
      let resAArr=JSON.parse(res.Msg)
    console.log('复式的数据',resAArr)
     
    
        }else{
          this.$message.error(res.Msg);
        }
      })
    },
    tuimaon(){
      console.log(this.tuimaArr,284)
      if(this.tuimaArr.length==0){
      this.$message.warning('请选择注单')
        return
      }
let obj={
  uuid: this.userInfo.Uuid,
      sid: this.userInfo.Sid,
      orderid:item.orderid
}
 
   this.$http.post('ordertuima',obj).then((res)=>{
    if(res.Status){
      this.$message.success(res.Msg);
      this.getGameTJ()
      // this.getList()
    }else{
      this.$message.error(res.Msg);
    }
   })



    },
    seltuima($event,item){
    
      item.selected=$event.target.checked
      if(item.selected){
        let hadIndex=this.tuimaArr.findIndex(id=>id==item.orderid)
        if(hadIndex==-1){
          this.tuimaArr.push(item.orderid)
        }
      }else{
        let hadIndex=this.tuimaArr.findIndex(id=>id==item.orderid)
        if(hadIndex>-1){
          this.tuimaArr.splice(hadIndex,1)
        }
      }
   this.$forceUpdate()
    },
    setDanXiang(obj){
      this.setorderArr=[obj];
      this.$refs.amount.focus();
    
      if (this.setorderArr.length > 0) {
        this.getOddsFororder();
      }
    },
     // 复式  下注
  getFushiDaata(){
    this.setorderArr=[];
    if(!this.haoma){
      this.selectInputContent();
    this.tipon('请输入下注项！')
      return
     }
    // 
    if (!/^(?!.*(\d).*\1)\d+$/.test(this.haoma)){
      this.selectInputContent();
      this.tipon('不允许出现重复号码！')
      return
     }
     let hmleng=this.haoma.length
     let strarr=this.haoma.split('')
       let xiazhunum=strarr.join(',')
       let item=fushi.find(item=>item.label==hmleng)
       if(item){
        let newobj=Object.assign({},item)
        newobj.num=xiazhunum
        newobj.label=newobj.label+'码'
        this.setorderArr.push(newobj);
        this.$refs.amount.focus();
       }
       if (this.setorderArr.length > 0) {
        this.getOddsFororder();
      }
  },
  getZuLiuData(){
    this.setorderArr=[];
      if(!this.haoma){
        this.tipon('最少输入两位数字！')
      return
      }
      let hmleng=this.haoma.length
     
       if(hmleng<2){
        this.selectInputContent();
       this.tipon('不支持下注类型！')
        return
       }
      //  if(hmleng==3){
      //   if(this.haoma[0]==this.haoma[1]&&this.haoma[0]==this.haoma[2]){
      //     this.selectInputContent();
      //  this.tipon('不支持下注类型！')
      //   return
      //   }
      
      //  }
       if(hmleng>=2){
        // 
           if (!/^(?!.*(\d).*\1)\d+$/.test(this.haoma)){
      this.selectInputContent();
      this.tipon('不允许出现重复号码！')
      return
     }
       }
       let strarr=this.haoma.split('')
       let xiazhunum=strarr.join(',')
       let item=zuxuan6.find(item=>item.label==hmleng)
       if(item){
        let newobj=Object.assign({},item)
        newobj.num=xiazhunum
        newobj.label=item.label+'码'
        this.setorderArr.push(newobj);
        this.$refs.amount.focus();
       }
       if (this.setorderArr.length > 0) {
        this.getOddsFororder();
      }
  


  },
  // 对子 下注
  getDuiziData(){
    this.setorderArr=[];
    if(!this.haoma){
        this.tipon('请输入一位数字！')
      return
      }
      let hmleng=this.haoma.length
      if(hmleng!=1&&hmleng!=10){
        this.selectInputContent();
       this.tipon('不支持下注类型！')
        return
      }
 
      let strarr=this.haoma.split('')
    
     let  item=duizi.find(item=>item.label==hmleng)
     let xiazhunum=strarr.join(',')
     if(item){
      let newobj=Object.assign({},item)
      newobj.num=xiazhunum
      if(item.label==10){
     newobj.label='全包'
      }else{
        newobj.label=''
      }
      this.setorderArr.push(newobj);
        this.$refs.amount.focus();
     }
     if (this.setorderArr.length > 0) {
        this.getOddsFororder();
      }
     
  },
    // 组三 下注
    getZusanData(){
      this.setorderArr=[];
      if(!this.haoma){
        this.tipon('最少输入两位数字！')
      return
      }
       let hmleng=this.haoma.length
       if(hmleng==1){
        this.selectInputContent();
       this.tipon('不支持下注类型！')
        return
       }
      //  if(hmleng==3){
      //   if(this.haoma[0]==this.haoma[1]&&this.haoma[0]==this.haoma[2]){
      //     this.selectInputContent();
      //     this.tipon('不支持下注类型！')
      //     return
      //   }
      //  }
       if(hmleng>2){
        // 
           if (!/^(?!.*(\d).*\1)\d+$/.test(this.haoma)){
      this.selectInputContent();
      this.tipon('位数大于2时，不允许出现重复号码！')
      return
     }
       }
       let strarr=this.haoma.split('')
       let set1=new Set(strarr)
       let newarr=Array.from(set1)
     
       let item=null
       if(newarr.length==1){
        item=zuxuan3.find(item=>item.label=='双飞')
       }else{
        item=zuxuan3.find(item=>item.label==hmleng)
       }
        let xiazhunum=strarr.join(',')
       
       if(item){
        let newobj=Object.assign({},item)
        newobj.num=xiazhunum
        if(newobj.label!='双飞'){
          newobj.label=item.label+'码'
        }
      
        this.setorderArr.push(newobj);
        this.$refs.amount.focus();
       }
       if (this.setorderArr.length > 0) {
        this.getOddsFororder();
      }
   
    },
  // 和值 下注
  getHeZhiData(){
    this.setorderArr=[];
    if(!this.haoma){
    this.tipon('和值的数值只允许在 0 ~ 27 之间！')
      return
     }
     if (!/^([0-9]|1\d|2[0-7])$/.test(this.haoma)){
      this.selectInputContent();
      this.tipon('和值的数值只允许在 0 ~ 27 之间！')
      return
     }
     let item=hezhi.find(item=>item.label=='和值'+this.haoma)
     if(item){
      this.setorderArr.push(item);
        this.$refs.amount.focus();
    }
    if (this.setorderArr.length > 0) {
        this.getOddsFororder();
      }



  },
  getYZXData(){
    this.setorderArr=[];
     if(!this.haoma){
    this.tipon('号码只能为1位数！')
      return
     }
     if (!/^\d{1}$/.test(this.haoma)){
      this.selectInputContent();
      this.tipon('号码只能为1位数！')
      return
     }
     let item=yizixian.find(item=>item.label==this.haoma)
    if(item){
      this.setorderArr.push(item);
        this.$refs.amount.focus();
    }
    if (this.setorderArr.length > 0) {
        this.getOddsFororder();
      }


  },
  // 二字定
  getERZDData(){
    this.setorderArr=[];
    if(!this.haoma){
    this.tipon('号码必须为2个数字1个X号！')
      return
     }
    //   
    if (!/^\d{2}X|\dX\d|X\d{2}$/.test(this.haoma)){
      this.selectInputContent();
      this.tipon('号码必须为2个数字1个X号！')
      return
     }
     let item=erziding.find(item=>item.label==this.haoma)
     if(item){
      this.setorderArr.push(item);
        this.$refs.amount.focus();
    }
    if (this.setorderArr.length > 0) {
        this.getOddsFororder();
      }
  },
  // 一字定 下注
  getYZDData(){
    this.setorderArr=[];
    if(!this.haoma){
    this.tipon('号码必须为1个数字2个X号！')
      return
     }
     if (!/^(?:\d[X]{2}|[X]\d[X]|[X]{2}\d)$/.test(this.haoma)){
      this.selectInputContent();
      this.tipon('号码必须为1个数字2个X号！')
      return
     }
     let item=yiziding.find(item=>item.label==this.haoma)
     if(item){
      this.setorderArr.push(item);
        this.$refs.amount.focus();
    }
    if (this.setorderArr.length > 0) {
        this.getOddsFororder();
      }


  },
 
    // 跨度  下注
    getKuaduData(){
      this.setorderArr=[];
     if(!this.haoma){
    this.tipon('号码只能为1位数！')
      return
     }
     if (!/^\d{1}$/.test(this.haoma)){
      this.selectInputContent();
      this.tipon('号码只能为1位数！')
      return
     }
     let item=kua.find(item=>item.label=='跨'+this.haoma)
    if(item){
      this.setorderArr.push(item);
        this.$refs.amount.focus();
    }
    if (this.setorderArr.length > 0) {
        this.getOddsFororder();
      }




    },
    // 直选  三字定为
    getZhiXuanData(){
      this.setorderArr=[];
     if(!this.haoma){
    this.tipon('号码只能为3位数！')
      return
     }
     if (!/^\d{3}$/.test(this.haoma)){
      this.selectInputContent();
      this.tipon('号码只能为3位数！')
      return
     }
    let item=zhixuan.find(item=>item.label==this.haoma)
    if(item){
      this.setorderArr.push(item);
              this.$refs.amount.focus();
    }
    if (this.setorderArr.length > 0) {
        this.getOddsFororder();
      }

    },
    tipon(str){
      this.$confirm(str, '提示框', {
          confirmButtonText: '确定',
          cancelButtonText: '',
          showCancelButton:false,
          type: 'error'
        }).then(() => {
          
        }).catch(() => {
                   
        });
    },
    // 获取未接明细
    getweijieList() {
   
      let hours = new Date().getHours();
      let begindate = null;
      let enddate = null;
      if (hours < 6) {
        begindate = this.splitDate(this.getBeforeDate(1));
        enddate = this.splitDate(this.getBeforeDate(1));
      } else {
        begindate = this.splitDate(this.getBeforeDate(0));
        enddate = this.splitDate(this.getBeforeDate(0));
      }
 
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        state: this.state,
        pagenum: 1,
        pagecount: 50,
        begindate,
        enddate,
        roomeng:this.currentGame.roomeng
      };
      this.$http
        .post("getlotorderinfos", obj)
        .then((res) => {
          if (res.Status) {
            if (res.Msg) {
              let resObj =JSON.parse(res.Msg);
           
              this.wjlist =resObj?resObj.data:[];
              this.tuimaArr=[]
           
              if(this.wjlist.length<10){
                for (let index = 0; index < 10; index++) {
                 if(!this.wjlist[index]){
                  let obj={}
                  this.wjlist.push(obj)
                 }
                  
                }
              }
              this.wjlist.forEach(item=>{
                item.selected=false
              })
              if(this.wjlist.length>12){
                var tbody = document.getElementById("tbody");
tbody.scrollTop = tbody.scrollHeight;

              }
           
           
            }else{
              this.wjlist=[]
              if(this.wjlist.length<10){
                for (let index = 0; index < 10; index++) {
                 if(!this.wjlist[index]){
                  let obj={}
                  this.wjlist.push(obj)
                 }
                  
                }
              }
              console.log(this.wjlist,719)
            }
          } else {
            this.$message.error(res.Msg);
          }
        })
        .catch((err) => {});
    },
    xiazhuon() {
      if (this.setorderArr.length > 0) {
        if (!this.amount) {
          this.$message.error("请输入下注金额");
          return;
        }

        // arrbet: [{"id":5459,"money":20},{"id":5460,"money":20}]
        let obj = {
          uuid: this.userInfo.Uuid,
          sid: this.userInfo.Sid,
          roomeng: this.currentGame.roomeng,
          pan: this.pan,
          arrbet: [],
        };
        let zhushu=this.setorderArr.length;
        this.setorderArr.forEach((item) => {
          let objbet = {
            id: item.ResultID,
            money: Math.trunc(Number(this.amount)),
          };
          if(item.num){
            objbet.num=item.num
          }
          obj.arrbet.push(objbet);
        });
        var timestamp1 = Date.now();
        obj.timestamp =Math.floor(timestamp1 / 1000);
      let tokenstr=this.userInfo.Uuid+this.userInfo.Sid+ obj.timestamp+this.qwer
     
      obj.token=md55(tokenstr)
      
        obj.arrbet = JSON.stringify(obj.arrbet);
        this.$http.post("setneworder", obj).then((res) => {
          if (res.Status) {
            this.haoma='';
            this.setorderArr=[]
            let arr = JSON.parse(res.Msg);
            console.log(arr[0],358)
            arr.forEach(item=>{
            item.label=this.bianXXXX(item)
            })
            this.$store.commit('setwxcArr',[...arr])
            this.$message.success('注单成功'+zhushu+'注');
            this.getweijieList()
          } else {
            this.$message.error(res.Msg);
          }
        });
      } else {
        this.$message.warning("请输入数据");
      }
    },
    shaixuanhaoma() {
      this.setorderArr = [];
      if (this.xcount >= 3) {
        this.selectInputContent();
        this.$message.warning({
          message: "号码格式有误",
          offset: 300,
        });
        return;
      }
      if (this.xcount > 0 && this.haoma.length < 4) {
        this.selectInputContent();
        this.$message.warning({
          message: "号码格式有误",
          offset: 300,
        });
        return;
      }
      if (this.haoma.length < 4) {
        // 长度小于 是现
        let strArr = this.haoma.split("");
        strArr.sort((a, b) => Number(a) - Number(b));
        let xianlabel = strArr.join("");
        let item = xianArr.find((item) => item.label == xianlabel);
        if (item) {
          // console.log(item, "现");
          this.setorderArr.push(item);
          this.$refs.amount.focus();
        }
      } else {
        // 四字线 和 全传都没有选中
        if (!this.isxian && !this.quanzhuan) {
          // 长度是4 定
          if (this.haoma.length == 4) {
            let item = dingArr.find((item) => item.label == this.haoma);
            if (item) {
              // console.log(item, "定");
              this.setorderArr.push(item);
              this.$refs.amount.focus();
            }
          }
        }
        if (this.isxian) {
          
          if (this.xcount > 0) {
            this.selectInputContent();
            this.$message.warning({
              message: "号码格式有误",
              offset: 300,
            });
            return;
          }
          // 长度小于 是现
          let strArr = this.haoma.split("");
          strArr.sort((a, b) => Number(a) - Number(b));
          let xianlabel = strArr.join("");
          let item = xianArr.find((item) => item.label == xianlabel);
          if (item) {
            // console.log(item, "四字现");
            this.setorderArr.push(item);
            this.$refs.amount.focus();
          }
        }
        if (this.quanzhuan) {
          if(this.xcount==0){
            let strArr = this.haoma.split("");
            ding4.forEach((item) => {
            let labelD = item.label.split("");
            let labelNumber = labelD.filter((lele) => this.isNumber(lele));
            let b = this.isContained(strArr, labelNumber);
            if (b) {
              this.setorderArr.push(item);
             
            }
          });
          }else if(this.xcount==1){
            let strArr = this.haoma.split("");
            ding3.forEach((item) => {
            let labelD = item.label.split("");
            let labelNumber = labelD.filter((lele) => this.isNumber(lele));
            let b = this.isContained(strArr, labelNumber);
            if (b) {
              this.setorderArr.push(item);
             
            }
          });
          }else if(this.xcount==2){
            let strArr = this.haoma.split("");
            ding2.forEach((item) => {
            let labelD = item.label.split("");
            let labelNumber = labelD.filter((lele) => this.isNumber(lele));
            let b = this.isContained(strArr, labelNumber);
            if (b) {
              this.setorderArr.push(item);
             
            }
          });
          }
     
          this.$refs.amount.focus();
         

        
        }
      }
      if (this.setorderArr.length > 0) {
        this.getOddsFororder();
      }
    },

    getOddsFororder() {
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        roomeng: this.currentGame.roomeng,
        pan: this.pan,
        itemid: this.setorderArr[0].ResultID,
      };
      // getitemodds
      this.$http.post("getcommonitemodds", obj).then((res) => {
        if (res.Status) {
          let obj = JSON.parse(res.Msg);

          this.Odds = obj[0].Odds;
        }
      });
    },
    haomachange1() {
      //对子
      if(this.ordertype=='duizi'){
     this.getDuiziData()
      }
      // 复式
      if(this.ordertype=='fushi'){
    this.getFushiDaata()
      }
      // 组六
      if(this.ordertype=='zuliu'){
     this.getZuLiuData()
      }
      // 组三
      if(this.ordertype=='zusan'){
     this.getZusanData()
      }
      if(this.ordertype=='zhixuan'){//直选
        this.getZhiXuanData()
      }
      // 跨度
      if(this.ordertype=='kua'){
        this.getKuaduData()
      }
      // 和值
      if(this.ordertype=='hezhi'){
       this.getHeZhiData()
      }
      
      // 一字定
      if(this.ordertype=='yiding'){
    this.getYZDData()
      }
      // 二字定
      if(this.ordertype=='erding'){
   this.getERZDData()
      }
      // 独胆  一字现
      if(this.ordertype=='dudan'){
       this.getYZXData()
      }
      // if (this.haoma && this.haoma.length < 4) {
      //   this.shaixuanhaoma();
      // } else {
      //   this.isDui = false;
      // }
    },
    selectInputContent() {
      this.$nextTick(() => {
        let haomaInput = document.getElementById("haoma");
        haomaInput.select();
      });
    },
    haomachange(e) {
      let val = e.target.value;
      this.haoma = val.replace(/[^\d]/g, "X");
     

    },
    quanzhuanchange(e) {
      let bool = e.target.checked;
      this.quanzhuan = bool;
      if(bool){
        this.shaixuanhaoma();
      }
    },
    isxianchange(e) {
      let bool = e.target.checked;
      this.isxian = bool;
      if(bool){
        this.shaixuanhaoma();
      }
    },
    bianXXXX(item){
      // console.log(item.gamedalei)
      let label=''
      let oldlabel=item.iteminfo;
      let labelArr=oldlabel.split('-');
      // console.log(labelArr)
      if(this.ordertype=='zhixuan'){
      label=labelArr[1]
      }else if(item.gamedalei=='跨度'){
      label=item.iteminfo;
      }else if(item.gamedalei=='两面'){
       label=labelArr[1]
      }else if(item.gamedalei=='总和'){
        label='和值'+labelArr[1]
      }else if(item.gamedalei=='一字定位'){
        label=labelArr[1]
      }else if(item.gamedalei=='二字定位'){
        if(labelArr[0]=='佰拾定位'){
          label=labelArr[1]+'X'
        }
        if(labelArr[0]=='佰个定位'){
          label=labelArr[1][0]+'X'+labelArr[1][1]
        }
        if(labelArr[0]=='拾个定位'){
          label='X'+labelArr[1]
        }
       
      }else if(item.gamedalei=='一字'){
        label= '独胆'+labelArr[1]
      }else if(item.gamedalei=='组选三'||item.gamedalei=='组选六'){
        label=oldlabel
      }else if(item.gamedalei=='复式'){
        label=oldlabel
      }
      
     
  
 
    return label
    },
    isContained(a, b) {
      //a是否包含b
      let numArrA = a.reduce(function (prev, next) {
        prev[next] = prev[next] + 1 || 1;

        return prev;
      }, {});
      let numArrB = b.reduce(function (prev, next) {
        prev[next] = prev[next] + 1 || 1;

        return prev;
      }, {});
      // a和b其中一个不是数组，直接返回false
      if (!(a instanceof Array) || !(b instanceof Array)) return false;
      const len = b.length;
      // a的长度小于b的长度，直接返回false
      if (a.length < len) return false;
      for (let i = 0; i < len; i++) {
        // 遍历b中的元素，遇到a没有包含某个元素的，直接返回false
        if (!a.includes(b[i])) {
          return false;
        } else {
          // 判断 a数组中包含B并且 数量要大于b数组中元素重复次数
          if (numArrA[b[i]] < numArrB[b[i]]) {
            return false;
          }
        }
      }

      // 遍历结束，返回true
      return true;
    },
  },
};
</script>
<style  scoped>

.quanbaoinput{
width: 20px;
height: 20px;
margin: 0 2px;
}
.xiazhulabel.redbor {
  border: 2px solid #ab243d;
}
.listmain > .data > h3 input {
  position: relative;
  top: 2px;
  margin-right: 5px;
}
.listmain > .data > h3 {
  text-align: left;
  box-sizing: border-box;
  padding-left: 10px;
}
.xiazhukuang {
  font-weight: bold;
}
h3 {
  position: relative;
}
.container {
  position: relative;
}
table tbody {
  display: block;

  max-height: 324px;
  overflow-x: hidden;
  overflow-y: auto;
}

table thead,
tbody tr {
  display: table;

  width: 100%;
  height: 26px;
  table-layout: fixed;
}
/* table thead {
  position: relative;
  overflow-y: scroll;
 } */
.tuima_btn {
  width: 40px;
  height: 22px;
  position: absolute;
  top: 1px;
  right: 43px;
  background: #bd0a2b;
  outline: none;
  color: #fff;
  border: none;
}
.tuima_btn:hover {
  background: #ab243d;
}
.com-table tr,
td,
th {
  position: relative;
  min-height: 26px;
  text-align: center;
  vertical-align: middle;
}

.com-table {
  width: 100%;
}
.inside-os {
  top: 0;
  width: auto !important;
  min-width: 100%;
  padding: 0;
}
.bl_wrap {
  flex: 1;
}
.ty_wrap {
  flex: 1.5;
}
.h3title {
  height: 26px;
  text-align: center;
  line-height: 26px;
  color: #fff;
}
.skin-blue .h3title {
  background: #2161b3;
}
.skin-brown .h3title {
  background: #a18361;
}
.skin-red .h3title {
  background: #c2455c;
}
.order_wrap {
  flex: 3;
}
.kd_flexcontainer > div {
  margin: 0 4px 0 0;
}
.kd_flexcontainer > div:nth-of-type(3) {
  margin-right: 0;
}
.kd_flexcontainer {
  margin-top: 4px;
  width: 100%;

  display: flex;
}
</style>